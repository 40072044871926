<!--
 * new page
 * @since: 2023-11-30
 * contactUs.vue
-->
<template>
  <div class="container">
    <div class="title">Contact Us</div>
    <div class="text">
      <!-- <Edit style="width: 1em; height: 1em; margin-right: 8px" />
      地址：新界葵涌嘉慶路5-9號8樓811室
      <br> -->
      <Edit style="width: 1em; height: 1em; margin-right: 8px" />
      Add：RoomA 19/F，CENTRE, 367-373 KING'S ROAD, NORTH POINT, HONG KONG
      <br>
      <Edit style="width: 1em; height: 1em; margin-right: 8px" />
      Tel：+852 5614 1001
      <br>
      <Edit style="width: 1em; height: 1em; margin-right: 8px" />
      Tel：+86 13692114291
      <br>
      <Edit style="width: 1em; height: 1em; margin-right: 8px" />
      E-mail：xsgn53936800@gmail.com
    </div>
    <div class="picture">
<!-- 
    <div class="contact">
      <h3>Whatsapp</h3>
      <img src="@/assets/whatsapp.png">
    </div>
    <div class="contact">
      <h3>WeChat</h3>
      <img src="@/assets/wechat.png">
    </div> -->
  
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, ref } from 'vue'
export default defineComponent({
  name: '',
  setup() {
    return {
    }

  }
});
</script>

<style scoped>

.picture{
  display: flex  ;
  justify-content: space-between;
  flex-wrap: wrap; /* 若宽度不够则往下排列 */

}
.container {
  background-color: #474747;
  position: fixed;
  top: 65%;
  width: 100%;
  height: 35%;
  display: flex; /* 弹性布局 */
  flex-wrap: wrap; /* 若宽度不够则往下排列 */
  overflow: auto; /* 铺满溢出背景色 */

  justify-content: flex-start; /* 将文本左对齐 */
  align-items: flex-start; /* 顶部对齐 */
}
.title{
  color: white; /* 设置文字颜色为蓝色 */
  margin-left: 70px; /* 调整左边距的值 */
  margin-top: 30px; /* 调整顶边距的值 */
  font-size: 24px;
}
.text {
  color: white; /* 设置文字颜色为蓝色 */
  margin-left: 70px; /* 调整左边距的值 */
  margin-top: 60px; /* 调整顶边距的值 */
  text-align: left; /* 将文本左对齐 */
}
.text br {
  display: block;
  content: "";
  height: 20px; /* 设置行间距为 20 像素 */
}
.contact{
  color: white; /* 设置文字颜色为蓝色 */
  margin-left: 70px; /* 调整左边距的值 */
  margin-top: 5px;
}
.contact img{
  width: 180px;
  height: 180px;
}
</style>
