import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HouTai from '../views/HouTai.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/main',
    meta: {
      title: '機電工程 | 湘世光年工程有限公司',
      keepAlive: true,
    },
    children: [
      // {
      //   path: '/engineering',
      //   name: 'engineering',
      //   component: () => import('@/components/main/engineering/engineeringViews.vue'),
      //   meta: {
      //     index: 'engineering' // 设置菜单项的索引值
      //   }
      // },
      {
        path: '/elec',
        name: 'elec',
        component: () => import('@/components/main/engineering/elec.vue'),
        meta: {
          index: '/elec' // 设置菜单项的索引值 电器
        }
      },
      {
        path: '/cooler',
        name: 'cooler',
        component: () => import('@/components/main/engineering/cooler.vue'),
        meta: {
          index: '/cooler' // 设置菜单项的索引值 冷电
        }
      },
      {
        path: '/zhizi',
        name: 'zhizi',
        component: () => import('@/components/main/aboutus/zhizi.vue'),
        meta: {
          index: '/zhizi' // 设置菜单项的索引值
        }
      },
      {
        path: '/aboutus',
        name: 'aboutus',
        component: () => import('@/components/main/aboutus/aboutUs.vue'),
        meta: {
          index: '/aboutus' // 设置菜单项的索引值
        }
      },
      // {
      //   path: '/shop',
      //   name: 'shop',
      //   component: () => import('@/components/shop/Shop.vue'),
      //   meta: {
      //     title: '湘世光年電器五金',
      //     index: 'shop' // 设置菜单项的索引值
      //   }
      // },
      {
        path: '/shop',
        name: 'shop',
        component: () => import('@/components/shop/four.vue'),
        meta: {
          title: '湘世光年電器五金',
          index: 'shop' // 设置菜单项的索引值
        }
      },
      {
        path: '/main',
        name: 'main',
        component: () => import('@/components/main/Main.vue'),
        meta: {
          index: 'main' // 设置菜单项的索引值
        }
      }
    ]
  },
  // {
  //   path: '/mingcheng/houtai/admin',
  //   name: 'admin',
  //   component: HouTai,
  //   children: [
  //     {
  //       path: '/mingcheng/houtai/admin/goodsCategory',
  //       name: 'goodsCategory',
  //       component: () => import('@/components/houtai/goodsCategory.vue'),
  //     },
  //     {
  //       path: '/mingcheng/houtai/admin/goodsList',
  //       name: 'goodsList',
  //       component: () => import('@/components/houtai/goodsList.vue'),
  //     },
  //   ]
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  mode:'hash',
  routes
})




// 在路由导航之前，使用全局前置守卫来设置页面标题
router.beforeEach((to, from, next) => {
  // 设置页面标题为要导航到的路由的meta字段中的title属性
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  } else {
    // 如果没有设置title，则可以设置默认标题
    document.title = '铭成'
  }
  next()
})

export default router
